<template>
  <header>
    <a href="/"><h1 class="logo">금전푸드</h1></a>
    <nav>
      <li><a href="#company">회사소개</a></li>
      <li><a href="#business">사업영역</a></li>
      <li><a href="#product">주요품목</a></li>
      <li><a href="#marketing">마케팅 방안</a></li>
    </nav>
    <div class="family_site"  @click="goToAllMarin()"><button>쇼핑몰</button></div>
  </header>
</template>

<script setup>
import {ref} from "vue"


function goToAllMarin() {
  window.location.href = "https://modunsusan.com"
}
</script>
