import { createRouter, createWebHistory } from 'vue-router'
import fullLayout from '../views/common/fullLayout.vue'

const router = createRouter({

  history: createWebHistory(),
  routes: [
  {
    path: '/',
    name: 'Intro',
    redirect: { name: 'main' },
    meta: {
      title: '금전푸드'
    }
  },
  {
    path: '/',
    component: fullLayout,
    children: [
      {
        path: '/',
        component: () => import('@/views/Main'),
        name: 'main',
      },
    ]},
  ],

})

export default router;
