<template>
  <footer>
    <div class="wrapper">
        <h2 class="logo_footer">금전푸드</h2>
        <div class="footer_text">
          <p>경기도 용인시 수지구 동천로437번길 8, 지층 (동천동)</p>
          <p class="copyright">Copyright © 금전푸드 Corp. All Rights Reserved.</p>
        </div>
      </div>
  </footer>
</template>

<script setup>


</script>
